.button {
    cursor: pointer;
    display: inline-flex;
    transition: background-color 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: background-color 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: background-color 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: background-color 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: background-color 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: background-color 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    vertical-align: middle;
}

.button.small {
    font-size: 16px;
    line-height: 24px;
}

.button.simple {
    border-radius: 96px 96px 96px 96px;
    -o-border-radius: 96px 96px 96px 96px;
    -ms-border-radius: 96px 96px 96px 96px;
    -moz-border-radius: 96px 96px 96px 96px;
    -khtml-border-radius: 96px 96px 96px 96px;
    -webkit-border-radius: 96px 96px 96px 96px;
    padding: 0px 8px 0px 8px;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.button.simple.border {
    border: 1px solid #ffffff;
}

.button.simple.padded {
    padding: 0px 10px 0px 10px;
}

.button.simple.padded-large {
    padding: 4px 14px 4px 14px;
}

.button.simple.rounded {
    padding: 6px 6px 6px 6px;
}

.button.simple.enabled {
    background-color: #50cb93;
}

.button.simple.file {
    position: relative;
}

.button.simple:focus,
.button.simple:hover {
    background-color: #50cb93;
}

.button.simple.red:focus,
.button.simple.red:hover {
    background-color: #e63946;
}

.button.simple:active {
    background-color: #2a9d8f;
}

.button.simple.red:active {
    background-color: #bf2a37;
}

.button.simple > img {
    margin-right: 6px;
    vertical-align: middle;
    width: 13px;
}

.button.simple.medium > img {
    width: 20px;
}

.button.simple.large > img {
    width: 28px;
}

.button.simple.very-large > img {
    width: 38px;
}

.button.simple > span {
    display: inline-block;
    vertical-align: middle;
}

.button.simple.no-text > img {
    margin-right: 0px;
    margin-top: 0px;
}

.button.simple.file > input[type="file"] {
    cursor: pointer;
    height: 100%;
    left: 0px;
    opacity: 0;
    -o-opacity: 0;
    -ms-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    position: absolute;
    top: 0px;
    vertical-align: top;
    width: 100%;
}

.button.simple.file > input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
}
