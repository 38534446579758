.overlay {
    align-items: center;
    background-color: rgba(80, 203, 147, 0.95);
    display: flex;
    font-size: 48px;
    height: 100%;
    justify-content: center;
    left: 0px;
    opacity: 0.0;
    -o-opacity: 0.0;
    -ms-opacity: 0.0;
    -moz-opacity: 0.0;
    -khtml-opacity: 0.0;
    -webkit-opacity: 0.0;
    pointer-events: none;
    position: fixed;
    text-align: center;
    top: 0px;
    transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 100%;
    z-index: 10;
}

.overlay.visible {
    opacity: 1.0;
    -o-opacity: 1.0;
    -ms-opacity: 1.0;
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
    -webkit-opacity: 1.0;
}

.overlay .overlay-image {
    margin-top: 16px;
}

.overlay .overlay-image > img {
    width: 64px;
}
