.button-container {
    margin-bottom: -12px;
}

.button-container > * {
    margin-bottom: 12px;
    margin-right: 8px;
}

.button-container > *:last-child {
    margin-right: 0px;
}
