.modal {
    align-items: center;
    background-color: rgba(20, 20, 20, 0.95);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0px;
    opacity: 0;
    -o-opacity: 0;
    -ms-opacity: 0;
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-opacity: 0;
    padding: 12px 12px 12px 12px;
    pointer-events: none;
    position: fixed;
    text-align: center;
    top: 0px;
    transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 100%;
    z-index: 10;
}

.modal.visible {
    opacity: 1.0;
    -o-opacity: 1.0;
    -ms-opacity: 1.0;
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
    -webkit-opacity: 1.0;
    pointer-events: initial;
    transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.modal > .modal-window {
    background-color: #264653;
    border-radius: 6px 6px 6px 6px;
    -o-border-radius: 6px 6px 6px 6px;
    -ms-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    -khtml-border-radius: 6px 6px 6px 6px;
    -webkit-border-radius: 6px 6px 6px 6px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    -khtml-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    max-height: 100%;
    max-width: 100%;
    outline: none;
    -o-outline: none;
    -ms-outline: none;
    -moz-outline: none;
    -khtml-outline: none;
    -webkit-outline: none;
    overflow-y: auto;
    padding: 24px 24px 24px 24px;
    text-align: left;
    transform: scale(0.96);
    -o-transform: scale(0.96);
    -ms-transform: scale(0.96);
    -moz-transform: scale(0.96);
    -khtml-transform: scale(0.96);
    -webkit-transform: scale(0.96);
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 480px;
}

.modal.visible > .modal-window {
    margin-top: 0px;
    pointer-events: all;
    transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -khtml-transform: scale(1);
    -webkit-transform: scale(1);
    transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), margin-top 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.modal > .modal-window > .modal-top-buttons {
    position: absolute;
    right: 14px;
    top: 14px;
}

.modal > .modal-window > .modal-title {
    font-size: 32px;
    margin-top: 0px;
    text-align: left;
}

.modal > .modal-window > .modal-contents {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.modal > .modal-window > .modal-text {
    font-size: 20px;
    line-height: 24px;
    margin: 0px 0px 0px 0px;
    overflow: auto;
}

.modal > .modal-window > .modal-buttons {
    font-size: 22px;
    margin-top: 24px;
    text-align: center;
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.modal > .modal-window > .modal-buttons > .button.simple {
    display: inline-block;
    margin-right: 12px;
    min-width: 120px;
}

.modal > .modal-window > .modal-buttons > .button.simple:last-child {
    margin-right: 0px;
}
