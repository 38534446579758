.keyboard-chip8 {
    font-size: 0px;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    touch-callout: none !important;
    -o-touch-callout: none !important;
    -ms-touch-callout: none !important;
    -moz-touch-callout: none !important;
    -khtml-touch-callout: none !important;
    -webkit-touch-callout: none !important;
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.keyboard-chip8 > .keyboard-line {
    margin-bottom: 12px;
}

.keyboard-chip8 > .keyboard-line:last-child {
    margin-bottom: 0px;
}

.keyboard-chip8 .key {
    border: 2px solid #ffffff;
    border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -ms-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    cursor: pointer;
    display: inline-block;
    font-size: 38px;
    height: 48px;
    line-height: 46px;
    margin-right: 14px;
    min-width: 48px;
    padding: 0px 6px 0px 6px;
    text-align: center;
}

.keyboard-chip8 .key:last-child {
    margin-right: 0px;
}

.keyboard-chip8 .key.pressed {
    background-color: #50cb93;
}
