.app {
    color: #ffffff;
    font-family: VT323, Roboto, Open Sans, Arial, Helvetica, sans-serif;
    margin: 0px auto 0px auto;
    max-width: 1400px;
}

.app h3 {
    margin: 0px 0px 16px 0px;
}

.app .display-container {
    margin-top: 78px;
}

@media only screen and (max-width: 1120px) {
    .app .display-container {
        margin-top: 14px;
    }
}
