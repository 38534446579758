.panel-split {
    display: flex;
}

@media only screen and (max-width: 1120px) {
    .panel-split {
        flex-direction: column;
    }
}

.panel-split > .side-left {
    display: flex;
    flex: 1 0;
    justify-content: center;
    text-align: center;
}

.panel-split > .side-right {
    flex: 0;
    max-width: 100%;
    min-width: 580px;
    padding: 0px 24px 0px 24px;
}

@media only screen and (max-width: 1120px) {
    .panel-split > .side-right {
        min-width: unset;
        padding: 0px 0px 0px 0px;
    }
}
