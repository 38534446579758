.link {
    border-bottom: 2px dotted #ffffff;
    color: #ffffff;
    text-decoration: none;
    transition: border 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: border 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: border 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: border 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: border 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: border 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.link:hover {
    border-bottom: 2px solid #ffffff;
}
