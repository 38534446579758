.title > .link,
.title > .label {
    margin-left: 14px;
}

.title > .icon {
    height: 32px;
    margin-left: 14px;
    vertical-align: top;
}
