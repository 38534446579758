.info {
    font-size: 24px;
    margin: 0px 0px 0px 0px;
    vertical-align: top;
}

.info.small {
    font-size: 18px;
}

.info > dt {
    clear: both;
    float: left;
    margin-top: 12px;
}

.info > dt:first-of-type {
    margin-top: 0px;
}

.info > dd {
    float: right;
    margin-top: 12px;
}

.info > dd:first-of-type {
    margin-top: 0px;
}

.info::after {
    clear: both;
    content: '';
    display: block;
}
