.panel-tab {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.panel-tab > .tab-selectors {
    line-height: 30px;
    margin-bottom: 26px;
}

.panel-tab > .tab-selectors > .tab-selector {
    border-bottom: 2px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-size: 22px;
    margin-right: 18px;
    opacity: 0.8;
    -o-opacity: 0.8;
    -ms-opacity: 0.8;
    -moz-opacity: 0.8;
    -khtml-opacity: 0.8;
    -webkit-opacity: 0.8;
    transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.panel-tab > .tab-selectors > .tab-selector:last-child {
    margin-right: 0px;
}

.panel-tab > .tab-selectors > .tab-selector:hover {
    opacity: 1.0;
    -o-opacity: 1.0;
    -ms-opacity: 1.0;
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
    -webkit-opacity: 1.0;
}

.panel-tab > .tab-selectors > .tab-selector.selected {
    border-bottom: 2px solid #ffffff;
    opacity: 1.0;
    -o-opacity: 1.0;
    -ms-opacity: 1.0;
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
    -webkit-opacity: 1.0;
}

.panel-tab > .tab-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
}
