.keyboard-container.fullscreen {
    bottom: 30px;
    left: 0px;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: 10;
}

@media only screen and (max-width: 1120px) {
    .keyboard-container.fullscreen {
        bottom: 0px;
    }
}

.keyboard-gb {
    font-size: 0px;
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    touch-callout: none !important;
    -o-touch-callout: none !important;
    -ms-touch-callout: none !important;
    -moz-touch-callout: none !important;
    -khtml-touch-callout: none !important;
    -webkit-touch-callout: none !important;
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.keyboard-gb.fullscreen {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 24px 24px 24px 24px;
    -o-border-radius: 24px 24px 24px 24px;
    -ms-border-radius: 24px 24px 24px 24px;
    -moz-border-radius: 24px 24px 24px 24px;
    -khtml-border-radius: 24px 24px 24px 24px;
    -webkit-border-radius: 24px 24px 24px 24px;
    margin: 0px auto 0px auto;
    max-width: 600px;
    padding: 18px 0px 18px 0px;
    pointer-events: initial;
}

@media only screen and (max-width: 600px) {
    .keyboard-gb.fullscreen {
        border-radius: 0px 0px 0px 0px;
        -o-border-radius: 0px 0px 0px 0px;
        -ms-border-radius: 0px 0px 0px 0px;
        -moz-border-radius: 0px 0px 0px 0px;
        -khtml-border-radius: 0px 0px 0px 0px;
        -webkit-border-radius: 0px 0px 0px 0px;
        padding: 12px 0px 12px 0px;
    }
}

.keyboard-gb > .keyboard-line {
    border-radius: 24px 24px 24px 24px;
    -o-border-radius: 24px 24px 24px 24px;
    -ms-border-radius: 24px 24px 24px 24px;
    -moz-border-radius: 24px 24px 24px 24px;
    -khtml-border-radius: 24px 24px 24px 24px;
    -webkit-border-radius: 24px 24px 24px 24px;
    margin-bottom: 12px;
}

.keyboard-gb > .keyboard-line:last-child {
    margin-bottom: 0px;
}

.keyboard-gb .key {
    border: 2px solid #ffffff;
    border-radius: 5px 5px 5px 5px;
    -o-border-radius: 5px 5px 5px 5px;
    -ms-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -khtml-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    cursor: pointer;
    display: inline-block;
    font-size: 38px;
    height: 48px;
    line-height: 46px;
    margin-right: 14px;
    min-width: 48px;
    padding: 0px 8px 0px 8px;
    text-align: center;
}

.keyboard-gb .key:last-child {
    margin-right: 0px;
}

.keyboard-gb .key.pressed {
    background-color: #50cb93;
}

.keyboard-gb > .dpad {
    float: left;
    text-align: center;
    width: 172px;
}

@media only screen and (max-width: 600px) {
    .keyboard-gb > .dpad {
        width: 160px;
    }
}

.keyboard-gb > .dpad > .dpad-top {
    margin-bottom: -3px;
}

.keyboard-gb > .dpad > .dpad-bottom {
    margin-top: -3px;
}

.keyboard-gb > .dpad .key {
    border: none;
    font-size: 24px;
    padding: 0px 0px 0px 0px;
}

.keyboard-gb > .dpad .key.pressed {
    background-color: #50cb93;
}

.keyboard-gb > .dpad .key.up {
    border-bottom: 3px solid transparent;
    border-left: 3px solid #ffffff;
    border-radius: 6px 6px 0px 0px;
    -o-border-radius: 6px 6px 0px 0px;
    -ms-border-radius: 6px 6px 0px 0px;
    -moz-border-radius: 6px 6px 0px 0px;
    -khtml-border-radius: 6px 6px 0px 0px;
    -webkit-border-radius: 6px 6px 0px 0px;
    border-right: 3px solid #ffffff;
    border-top: 3px solid #ffffff;
}

.keyboard-gb > .dpad .key.left {
    border-bottom: 3px solid #ffffff;
    border-left: 3px solid #ffffff;
    border-radius: 6px 0px 0px 6px;
    -o-border-radius: 6px 0px 0px 6px;
    -ms-border-radius: 6px 0px 0px 6px;
    -moz-border-radius: 6px 0px 0px 6px;
    -khtml-border-radius: 6px 0px 0px 6px;
    -webkit-border-radius: 6px 0px 0px 6px;
    border-right: 3px solid transparent;
    border-top: 3px solid #ffffff;
    margin-right: 44px;
}

.keyboard-gb > .dpad .key.right {
    border-bottom: 3px solid #ffffff;
    border-left: 3px solid transparent;
    border-radius: 0px 6px 6px 0px;
    -o-border-radius: 0px 6px 6px 0px;
    -ms-border-radius: 0px 6px 6px 0px;
    -moz-border-radius: 0px 6px 6px 0px;
    -khtml-border-radius: 0px 6px 6px 0px;
    -webkit-border-radius: 0px 6px 6px 0px;
    border-right: 3px solid #ffffff;
    border-top: 3px solid #ffffff;
    margin-left: -3px;
    margin-right: 0px;
}

.keyboard-gb > .dpad .key.center {
    border-radius: 0px 0px 0px 0px;
    -o-border-radius: 0px 0px 0px 0px;
    -ms-border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    -khtml-border-radius: 0px 0px 0px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
    margin-right: 0px;
    pointer-events: none;
}

.keyboard-gb > .dpad .key.down {
    border-bottom: 3px solid #ffffff;
    border-left: 3px solid #ffffff;
    border-radius: 0px 0px 6px 6px;
    -o-border-radius: 0px 0px 6px 6px;
    -ms-border-radius: 0px 0px 6px 6px;
    -moz-border-radius: 0px 0px 6px 6px;
    -khtml-border-radius: 0px 0px 6px 6px;
    -webkit-border-radius: 0px 0px 6px 6px;
    border-right: 3px solid #ffffff;
    border-top: 3px solid transparent;
}

.keyboard-gb > .action {
    float: right;
    margin-right: 18px;
}

@media only screen and (max-width: 600px) {
    .keyboard-gb > .action {
        margin-right: 12px;
    }
}

.keyboard-gb > .action > .key {
    border-radius: 32px 32px 32px 32px;
    -o-border-radius: 32px 32px 32px 32px;
    -ms-border-radius: 32px 32px 32px 32px;
    -moz-border-radius: 32px 32px 32px 32px;
    -khtml-border-radius: 32px 32px 32px 32px;
    -webkit-border-radius: 32px 32px 32px 32px;
    border-width: 3px;
    font-size: 30px;
    font-weight: 900;
    height: 58px;
    line-height: 52px;
    width: 58px;
}

.keyboard-gb > .action > .key.a {
    position: relative;
    right: 0px;
    top: 10px;
}

.keyboard-gb > .action > .key.b {
    position: relative;
    right: 2px;
    top: 50px;
}

.keyboard-gb > .options {
    margin-top: 32px;
}

@media only screen and (max-width: 600px) {
    .keyboard-gb > .options {
        margin-top: 22px;
    }
}

.keyboard-gb > .options > .key {
    font-size: 14px;
    font-weight: 900;
    height: 30px;
    line-height: 26px;
    margin-left: 16px;
    margin-right: 16px;
    min-width: 100px;
}

.keyboard-gb > .break {
    clear: both;
}
