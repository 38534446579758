.button-increment {
    display: inline-block;
}

.button-increment > .value {
    margin: 0px 8px 0px 8px;
}

.button-increment > .prefix {
    margin-left: 8px;
}

.button-increment > .suffix {
    margin-right: 8px;
}
