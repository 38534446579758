.section {
    display: none;
}

.section.visible {
    display: block;
}

.section > .separator {
    background: #ffffff;
    height: 2px;
    margin: 22px 0px 22px 0px;
}
