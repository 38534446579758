.square {
    cursor: pointer;
    display: inline-block;
    height: 24px;
    transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -khtml-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 24px;
}

.square:hover {
    transform: scale(1.4);
    -o-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -khtml-transform: scale(1.4);
    -webkit-transform: scale(1.4);
}

.square:active {
    transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -khtml-transform: scale(0.9);
    -webkit-transform: scale(0.9);
}
